<template>
    <div class="contact-form">
            <form @submit.prevent="onSubmit">
              <template v-if="!success">
              <div class="row">
                    <!-- name -->
                    <div class="form-group" :class="{ error: v$.form.name.$errors.length }">
                        <input class="form-control" :placeholder="$t('contact.form.name')" type="text" v-model="v$.form.name.$model">
                        <!-- error message -->
                        <template v-if="v$.form.name.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'required'">{{ $t("contact.form.required") }}</div>
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'max'">{{ $t("contact.form.max", {max : v$.form.name.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>                  
              </div>

                <div class="row">
                    <!-- Email -->
                    <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                        <input class="form-control" :placeholder="$t('contact.form.email')" type="email" v-model="v$.form.email.$model">
                        <!-- error message -->
                        <template v-if="v$.form.email.$errors.length > 0">                
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">C{{ $t("contact.form.required") }}</div>
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">{{ $t("contact.form.invalidEmail") }}</div>
                            <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">{{ $t("contact.form.max", {max : v$.form.email.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>
                </div>

              <div class="row">
                    <!-- Phone -->
                    <div class="form-group" :class="{ error: v$.form.phone.$errors.length }">
                        <input class="form-control" :placeholder="$t('contact.form.phone')" type="text" v-model="v$.form.phone.$model">
                        <!-- error message -->
                        <template v-if="v$.form.phone.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.phone.$errors[0].$validator == 'max'">{{ $t("contact.form.max", {max : v$.form.phone.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>
                </div>

                <div class="row">                
                    <!-- Subject -->
                    <div class="form-group" :class="{ error: v$.form.subject.$errors.length }">
                        <input class="form-control" :placeholder="$t('contact.form.subject')" type="text" v-model="v$.form.subject.$model">
                        <!-- error message -->
                        <template v-if="v$.form.subject.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.subject.$errors[0].$validator == 'max'">{{ $t("contact.form.max", {max : v$.form.subject.$errors[0].$params.max}) }}</div>
                        </template>
                    </div>                
                </div>

                <div class="row">
                    <!-- Message -->
                    <div class="form-group" :class="{ error: v$.form.message.$errors.length }">
                        <textarea class="form-control" :placeholder="$t('contact.form.message')" v-model="v$.form.message.$model" rows="5"></textarea>
                        <!-- error message -->
                        <template v-if="v$.form.message.$errors.length > 0">
                            <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                                <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">{{ $t("contact.form.required") }}</div>
                                    <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'max'"> {{ $t("contact.form.max", {max : v$.form.message.$errors[0].$params.max}) }}</div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="row">
                    <!-- rgpd -->
                    <div class="form-group" :class="{ error: errCheck}">
                        <div class="checkbox-container">
                            <input class="form-control" type="checkbox" v-model="v$.form.rgpd.$model" @click="getChecked(v$.form.rgpd.$model)">
                            <label>{{ $t("contact.form.rgpd") }} <router-link :to="{ name : 'DataPrivacy' }" target="_blank">{{ $t("contact.form.rgpdLink") }}</router-link></label>
                        </div>
                        <!-- error message -->
                        <template v-if="v$.form.rgpd.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">{{ $t("contact.form.required") }}</div>
                        </template>
                    </div>
                </div>
                
                <!-- Submit Button -->
                <div class="row-btn">
                    <button :disabled="v$.form.$invalid" class="btn">{{ $t("contact.form.btn") }}</button>
                </div>
                </template>
                <!-- Success -->
                <div class="notices success" v-if="success">{{ $t("contact.form.success")}}</div>
                <!-- Errors -->
                <div class="notices errors" v-if="errors">{{ errors}}</div>
          </form>
            <div class="loader-container">
                <loader v-if="isLoading"></loader>
            </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';
import axios from 'axios';
import Loader from './Loader.vue';

export default {
    name : "ContactForm",
    components : { Loader },
    data() {
        return {
            form : {
                name : "",
                email : "",
                phone : "",
                subject : "",
                message : "",
                rgpd : false
            },
            success : false,
            errors : null,
            isLoading : false,
            errCheck : false
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            form : {
                name : {
                    required, max: maxLength(150) 
                },    
                email: {
                    required, email, max: maxLength(150) 
                },
                phone : {
                    max: maxLength(20) 
                },  
                subject : {
                    max: maxLength(250), required
                }, 
                message : {
                    required, max: maxLength(1500) 
                },
                rgpd : {
                    required,
                    checked: value => value === true
                }         
            }
        }        
    },
    methods : {
        onSubmit() {
            let config = {
                validateStatus: () => true,  
            };
            let vm = this;
            this.errors = null;
            this.success = false;

            this.form.site = 2;

            axios
                .post(`${this.$store.state.apiUrl}/public/contact/3`, this.form,  config)
                .then(function (res) { 
                    if(res.status != 200) {
                        vm.errors =  res.data.errors;                  
                    }else{
                        vm.success = true;
                    
                    }
                })
                .catch(error => {
                    console.log(error.response.data.errors);
                    vm.errors = vm.$t("contact.form.error");
                });
                
        }
    }
}
</script>

<style scoped lang="scss">
  form {
        width:800px;
        max-width:96%;
        margin:0 auto;
        position:relative;
        z-index:1;
    }

    .form-group {
        width:100%;
        display: flex;
        flex-direction: column;
        margin:10px 0;
    }

    .loader-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .btn {
        margin-top:20px;
    }
</style>